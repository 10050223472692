const menus = document.querySelector('.menus');
const btnOpenMobileMenu = document.querySelector('.mobile-menu');
const btnCloseMobileMenu = document.querySelector('.close-menu-btn');
const dropdownSubMenu = document.querySelector('.dropdown');
const queryMenuMobile = window.matchMedia("(max-width: 1024px)")
const activeMenuMobile = document.querySelector('.active-menu-mobile');
const firstLink = document.querySelector('.menu-item-has-children a');

btnOpenMobileMenu.addEventListener('click', function openMenu() {
  menus.classList.add('active-menu-mobile');
  document.body.style.overflow = 'hidden';
});

btnCloseMobileMenu.addEventListener('click', function closeMenu() {
  menus.classList.remove('active-menu-mobile');
  document.body.style.overflow = 'visible';
});

function checkQueryMobile(x) {
  var header = document.querySelector('header');
  if (!x.matches) {
    dropdownSubMenu.classList.add('useHover');
    menus.classList.remove('active-menu-mobile');
    dropdownSubMenu.removeEventListener('click', toggleShowSubMenu);
    dropdownSubMenu.classList.remove('showSubMenu');
  } else {
    dropdownSubMenu.classList.remove('useHover');
    dropdownSubMenu.addEventListener('click', toggleShowSubMenu);
  }
}

function toggleShowSubMenu() {
  dropdownSubMenu.classList.toggle('showSubMenu');
}

checkQueryMobile(queryMenuMobile);
queryMenuMobile.addEventListener('change', checkQueryMobile);

  //Sticky header after past hero section
  function stickyHeader() {
    if(document.querySelector('header') !== null){
      var header = document.querySelector('header');
      var headerHeight = header.offsetHeight;
  
      if (window.pageYOffset >= headerHeight) {
        header.classList.remove('ranimate-header');
        setTimeout(function () {
          header.classList.add('go-sticky');
        });
      } else {
        header.classList.add('ranimate-header');
        setTimeout(function () {
          header.classList.remove('go-sticky');
        }, 0);
      }
    } else {
      return;
    }
  }

  window.onscroll = function(){stickyHeader()};

  dropdownSubMenu.addEventListener('click', evt => {

    if(!queryMenuMobile.matches && evt.target.classList.contains('dropdown-menu')){
      window.location = dropdownSubMenu.querySelector('a').href;
    }
  });

  firstLink.addEventListener('click', evt => {
    evt.preventDefault();
  });